import React, { useState, useEffect, useCallback } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

const SearchSelect = ({ placeHolder, data, onChange, selectedItem, hasGroup, name, ...props }) => {
    const [value, setValue] = useState(selectedItem ? selectedItem : '');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    useStyles(classes);


    const getGrouppedOptions = useCallback(() => {
        return Object.keys(data).map(item => {
            return {
                type: 'group',
                name: item,
                items: data[item].map(element => ({ value: element.id, label: element.name }))
            }
        })
    }, [data]);

    const getOptions = useCallback(() => {
        return data.map(item => {
            return { label: item.name, value: item.id };
        });
    }, [data]);

    useEffect(() => {
        const options = hasGroup ? getGrouppedOptions() : getOptions();
        setDropdownOptions(options);
    }, [data])

    const handleChange = item => {
        setValue(item);
        onChange(name ? { value: item.value, name: name} : item.value);
    };

    return (
        <div>
            <Dropdown
                menuClassName={classes.menuDesplegado}
                data-testid='search-select'
                options={dropdownOptions} 
                onChange={handleChange}
                value={value}
                placeholder={placeHolder}
                {...props}
            />
        </div>
    )
}

SearchSelect.defaultProps = {
    hasGroup: false
}

export default SearchSelect;