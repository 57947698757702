import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

const SearchLabel = ({ label, htmlFor, iconName, uppercase, optional, customClasses }) => {
    useStyles(classes);
    const { t } = useTranslation('searchLabel');
    const style = {
        textTransform: uppercase 
            ? 'uppercase'
            : 'initial'
    };

    return (
        <label className={`${classes.labels} ${customClasses.labels}`} htmlFor={htmlFor} style={style}>
            { iconName && <Icon classes={`${classes.icon} ${customClasses.icon}`} name={iconName} /> }
            { label }
            { optional && <span className={classes.optional}> { t('optional') } </span> }
        </label>
    )
}

SearchLabel.defaultProps = {
    customClasses:{
      icon: '',
      labels: ''
    },
    optional: false
  };
  
  export default SearchLabel;