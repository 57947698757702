import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ onSubmit, title, gridTemplateRows, gap, customClasses, children, ...rest }) => {
    useStyles(classes);
    
    return (
        <form
            data-testid='form'
            id='form'
            className={customClasses ? `${classes.root} ${customClasses.root}` : classes.root} 
            onSubmit={onSubmit} 
            style={{ gridTemplateRows: gridTemplateRows, gridGap: gap }}
            {...rest} 
        >
            { title && <h1 className={classes.title}> { title } </h1> }
            { children }
        </form>
    )
}