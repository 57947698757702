// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._128991e7b68b9548abd82ab42e83ec77-scss{top:auto;width:100%}\n", ""]);
// Exports
exports.locals = {
	"menuDesplegado": "_128991e7b68b9548abd82ab42e83ec77-scss"
};
module.exports = exports;
