// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._01b874ac530444ff2a106fb497daad8e-scss{color:var(--searchBox-font-color);font-size:1rem;font-weight:bold;line-height:1.7}._2a9d21ff4a3802eda94330bbe885024c-scss{display:inline-block;fill:var(--white-light);width:10px;margin-right:5px}._4a0c8558be368ad360cc632fe66cf048-scss{font-size:small}\n", ""]);
// Exports
exports.locals = {
	"labels": "_01b874ac530444ff2a106fb497daad8e-scss",
	"icon": "_2a9d21ff4a3802eda94330bbe885024c-scss",
	"optional": "_4a0c8558be368ad360cc632fe66cf048-scss"
};
module.exports = exports;
